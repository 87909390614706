<template>
  <div>
    <div class="progress-container">
      <div class="progress-bar" :style="barStyle">
        <div class="progress-text">{{ value }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "progress-bar",
  props: {
    value: Number,
  },
  computed: {
    barStyle() {
      return {
        width: `${this.value}%`,
      };
    },
  },
};
</script>

<style scoped lang="stylus">
.progress-container
  background-color #DBDCE1
  border-radius 4px
  overflow hidden
  position relative

.progress-bar
  background-color #4588F1
  position relative
  height 1rem
  display flex
  justify-content flex-end
  align-items center

.progress-text
  color white
  font-size 0.8rem
  margin-right 4px
</style>
