<template lang="pug">
#report
  img.img(ref="report")
</template>

<script>
export default {
  data() {
    return {
      form: {
        question1: 0,
        question2: 0,
        question3: 0,
        question4: 0,
        question5: 0,
        question6: 0,
        question7: 0,
        question8: 0,
        question9: 0,
        question10: 0,
      },
      result: {
        type: "",
        description: "",
        advice1: "",
        advice2: "",
        advice3: "",
        advice4: "",
        advice5: "",
        advice6: "",
        image1: new Image(),
        image2: new Image(),
        image3: new Image(),
      },
    };
  },
  methods: {
    reportData() {
      this.form = JSON.parse(window.localStorage.getItem("report"));
      if (this.form.question1 == 1) {
        this.result.type = "外贸工厂";
        this.result.description =
          "您的企业以代工模式 / B2B 模式起步，积累了较强的产品研发生产能力。但受制于\n代工利润的压力，正逐步向品牌独立站和品牌化运营转型。";
      } else if (this.form.question2 == 1) {
        this.result.type = "平台卖家";
        this.result.description =
          "您的企业通过在海外第三方平台销售起步，积累了强大的供应链实力与一定用户资\n源。但平台难以做到用户沉淀，正逐步向品牌独立站转型。";
      } else if (this.form.question2 == 2) {
        this.result.type = "流量导向独立站";
        this.result.description =
          "您的企业通过拉新铺货模式快速做大规模，运营多个独立站，但供应链能力薄弱，\n用户体验差导致低复购率，正逐步向品牌化运营转型。";
      } else if (this.form.question3 == 1) {
        this.result.type = "渠道品牌导向独立站";
        this.result.description =
          "您的企业主要围绕单个独立站运营，供应链能力强，实现高速的产品响应及换新，\n逐步重视品牌打造及跨品类拓展。";
      } else if (this.form.question3 == 2) {
        this.result.type = "产品品牌导向独立站";
        this.result.description =
          "您的企业主要围绕单个独立站运营，建立初期就重视品牌打造，擅长对细分品类的\n精细打磨，逐步拓展产品品类。";
      } else {
        this.result.type = "国内品牌出海";
        this.result.description =
          "您的企业依托淘宝及国内市场发展起来的互联网原生品牌，拥有一定的品牌运营\n能力，正逐步通过独立站向海外市场拓展。";
      }

      if (
        this.form.question4 == 1 &&
        (this.form.question5 == 1 || this.form.question5 == 2)
      ) {
        this.result.advice1 =
          "1. 积累多维度产品标签（例如面料、版型、主打人群、场景\n    等）、产品表现数据（点击量、转化率量）、外部市场趋势数\n    据，进行精细化分析以输出产品规划";
      } else if (
        this.form.question4 == 1 &&
        (this.form.question5 == 3 || this.form.question5 == 4)
      ) {
        this.result.advice1 =
          "1. 通过内部销售数据精细化分析及外部市场数据跟踪分析，\n    驱动周期性上新规划";
      } else if (this.form.question4 == 1 && this.form.question5 == 5) {
        this.result.advice1 =
          "1. 持续深化内部数据及外部数据分析，坚持数据驱动上新";
      } else if (
        this.form.question4 == 2 &&
        (this.form.question5 == 1 || this.form.question5 == 2)
      ) {
        this.result.advice1 =
          "1. 开展用户痛点调研、分析竞品产品评论，针对性开发功能优\n    势产品";
      } else if (
        this.form.question4 == 2 &&
        (this.form.question5 == 3 || this.form.question5 == 4)
      ) {
        this.result.advice1 = "1. 开展用户痛点调研，针对性开发功能优势产品";
      } else {
        this.result.advice1 =
          "1. 持续挖掘用户痛点，针对性提升产品性能及开发新产品";
      }

      if (this.form.question6 < 3) {
        this.result.advice2 = "1. 收集并搭建用户画像数据库";
      } else if (this.form.question6 < 5) {
        this.result.advice2 = "1. 全链路打通用户行为及画像数据，用于指导运营";
      } else {
        this.result.advice2 =
          "1. 持续挖掘细分用户画像需求、及时洞察画像变化，调整运营\n    策略";
      }

      if (this.form.question7 < 3) {
        this.result.advice3 = "2. 收集并搭建用户行为数据体系";
      } else if (this.form.question7 < 5) {
        this.result.advice3 = "2. 明确购物旅程各环节行为的核心指标和优化负责人";
      } else {
        this.result.advice3 =
          "2. 搭建 A / B 测试体系，通过实验快速迭代，验证用户在购物\n    旅程各环节行为中流失的假设";
      }

      if (this.form.question8 < 3) {
        this.result.advice4 = "1. 在官网设立单独页面为品牌背景做介绍";
      } else if (this.form.question8 < 5) {
        this.result.advice4 = "1. 围绕功能性或精神性价值构建品牌故事";
      } else {
        this.result.advice4 =
          "1. 确保公司的经营理念及商业战略能够渗透到传递给消费者的\n    品牌心智里";
      }

      if (this.form.question9 < 3) {
        this.result.advice5 =
          "2. 规划开展自家产品图片的拍摄，以及广告素材的设计和优化";
      } else if (this.form.question9 < 5) {
        this.result.advice5 =
          "2. 广告素材元素，例如品牌宣言、品牌视觉、产品图、文案表\n    述等，有一致品牌风格";
      } else {
        this.result.advice5 =
          "2. 确保所有跟消费者有接触的节点，如产品包装、效果及品牌\n    广告、促销活动等，都传递一致的品牌心智";
      }

      if (this.form.question10 < 3) {
        this.result.advice6 =
          "3 尝试不同广告产品组合，如展示广告、搜索广告、再营销等";
      } else if (this.form.question10 < 5) {
        this.result.advice6 =
          "3. 针对如何结合品牌营销和效果营销带来 1 + 1 > 2 的传播\n    效果进行布局";
      } else {
        this.result.advice6 =
          "3. 持续追踪品牌舆情趋势，并拓展新兴付费及非付费渠道，如\n    网红、直播营销等";
      }

      const a = (this.form.question5 * 20.0).toFixed(0);
      this.result.image1.src = `/static/google-charts/${a}.png`;

      const b = ((this.form.question6 + this.form.question7) * 10.0).toFixed(0);
      this.result.image2.src = `/static/google-charts/${b}.png`;

      const c = (
        ((this.form.question8 + this.form.question9 + this.form.question10) /
          15.0) *
        100.0
      ).toFixed(0);
      this.result.image3.src = `/static/google-charts/${c}.png`;
    },
    generateReport() {
      var canvas = document.createElement("canvas");
      canvas.width = 600;
      canvas.height = 900;

      const ctx = canvas.getContext("2d");

      ctx.font = "bold 30px sans-serif";
      ctx.fillText(this.result.type, 10, 50);

      ctx.font = "normal 16px sans-serif";
      ctx.fillText(this.result.description.split("\n")[0], 10, 90);
      ctx.fillText(this.result.description.split("\n")[1], 10, 120);

      ctx.font = "bold 24px sans-serif";
      ctx.fillText("产品评分", 10, 160);

      ctx.font = "normal 16px sans-serif";
      ctx.fillText("我们有如下建议", 157, 200);

      let lineStart = 230;
      this.result.advice1.split("\n").forEach((text) => {
        ctx.fillText(text, 140, lineStart);
        lineStart += 30;
      });

      ctx.font = "bold 24px sans-serif";
      ctx.fillText("用户评分", 10, 340);

      ctx.font = "normal 16px sans-serif";
      ctx.fillText("我们有如下建议", 157, 380);
      lineStart = 410;
      [this.result.advice2, this.result.advice3]
        .join("\n")
        .split("\n")
        .forEach((text) => {
          ctx.fillText(text, 140, lineStart);
          lineStart += 30;
        });
      // ctx.fillText(`1. ${this.result.advice2}`, 140, 410);
      // ctx.fillText(`2. ${this.result.advice3}`, 140, 440);

      ctx.font = "bold 24px sans-serif";
      ctx.fillText("品牌评分", 10, 520);

      ctx.font = "normal 16px sans-serif";
      ctx.fillText("我们有如下建议", 157, 560);
      lineStart = 590;
      [this.result.advice4, this.result.advice5, this.result.advice6]
        .join("\n")
        .split("\n")
        .forEach((text) => {
          ctx.fillText(text, 140, lineStart);
          lineStart += 30;
        });

      if (window.screen.width < 1280) {
        ctx.fillText("长按图片保存您的专属报告", 70, 815);
      } else {
        ctx.fillText("右键另存为您的专属报告", 70, 815);
      }

      let images = 0;
      const icon = new Image();
      icon.src = "/static/report-icon.png";

      const onloaded = () => {
        ctx.drawImage(this.result.image1, 10, 180, 120, 120);
        ctx.drawImage(this.result.image2, 10, 350, 120, 120);
        ctx.drawImage(this.result.image3, 10, 530, 120, 120);
        ctx.drawImage(icon, 10, 780, 48, 60);
        this.$refs.report.src = canvas.toDataURL();
      };

      this.$refs.report.src = canvas.toDataURL();
      this.result.image1.onload = () => {
        images++;
        if (images == 4) {
          onloaded();
        }
      };
      this.result.image2.onload = () => {
        images++;
        if (images == 4) {
          onloaded();
        }
      };
      this.result.image3.onload = () => {
        images++;
        if (images == 4) {
          onloaded();
        }
      };
      icon.onload = () => {
        images++;
        if (images == 4) {
          onloaded();
        }
      };
    },
  },
  mounted() {
    this.$pushEvent("visit", { route: "at_report" });
    this.reportData();
    this.generateReport();
  },
};
</script>

<style lang="stylus" scoped>
.img {
  max-width: 100%;
  -webkit-touch-callout: default;
}
</style>
