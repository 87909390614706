<template lang="pug">
full-page(ref="fullpage", :options="options")
  .section.section-cover
    img.google-shopify-logo(src="~@/assets/images/logo-google-shopify.png")
    .section-content
      h1.my-8
        | 品牌站诊断分析
      p.description-text.my-8
        | 谷歌与 Shopify 联合推出品牌站孵化项目，根据您的企业特点，定向提供产品，技术，内容和服务，解决大家在品牌站发展中遇到的各种问题，助您的品牌站更快起步，加速成长。还将提供专家咨询和支持服务。加入该项目，获得加速成长。
      md-button.md-dense.md-raised.md-primary.line-btn(@click="nextQuestion")
        | 开始测试
  .section
    p.progess-p
      | 当前进度
    progress-bar.progress(:value="0").my-4
    h2.my-4
      | 1. 您企业主要的销售渠道?
    .radio-group
      md-radio.md-primary(v-model="form.question1", :value="1")
        | 海外第三方平台（如 eBay、亚马逊、速卖通、阿里巴巴）
      md-radio.md-primary(v-model="form.question1", :value="2")
        | 海外独立站
      md-radio.md-primary(v-model="form.question1", :value="3")
        | 国内第三方平台（如淘宝、京东）
      md-radio.md-primary(v-model="form.question1", :value="4")
        | 国内线下渠道
      md-radio.md-primary(v-model="form.question1", :value="5")
        | 其它
        md-field.inline-field(md-inline)
          md-input.inline-input(v-model="form.channel")
    md-button.md-dense.md-raised.md-primary.line-btn(@click="nextQuestion")
      | 下一题
  .section(v-if="form.question1")
    span.clickable(@click="rollbackQuestion")
      md-icon.span-icon undo
      .mini-spacer
      span 返回上一题
    p.progess-p
      | 当前进度
    progress-bar.progress(:value="20").my-4
    h2.my-4
      | 2. 您企业 2020 年的海外销售额？
    .radio-group
      md-radio.md-primary(v-model="form.question2", :value="1")
        | < 15 万美金
      md-radio.md-primary(v-model="form.question2", :value="2")
        | 15 - 150 万美金
      md-radio.md-primary(v-model="form.question2", :value="3")
        | 150 - 750 万美金
      md-radio.md-primary(v-model="form.question2", :value="4")
        | > 750 万美金
    md-button.md-dense.md-raised.md-primary.line-btn(@click="nextQuestion")
      | 下一题
  .section(v-if="form.question2")
    span.clickable(@click="rollbackQuestion")
      md-icon.span-icon undo
      .mini-spacer
      span 返回上一题
    p.progess-p
      | 当前进度
    progress-bar.progress(:value="40").my-4
    h2.my-4
      | 3. 您企业主营的产品品类？
    .radio-group
      md-radio.md-primary(v-model="form.question3", :value="1")
        | 女装时尚产品
      md-radio.md-primary(v-model="form.question3", :value="2")
        | 美妆护肤产品
      md-radio.md-primary(v-model="form.question3", :value="3")
        | 消费电子产品
      md-radio.md-primary(v-model="form.question3", :value="4")
        | 家具园艺产品
      md-radio.md-primary(v-model="form.question3", :value="5")
        | 其它
        md-field.inline-field
          md-input.inline-input(v-model="form.category")
    md-button.md-dense.md-raised.md-primary.line-btn(@click="nextQuestion")
      | 下一题
  .section(v-if="form.question3")
    span.clickable(@click="rollbackQuestion")
      md-icon.span-icon undo
      .mini-spacer
      span 返回上一题
    p.progess-p
      | 当前进度
    progress-bar.progress(:value="60").my-4
    h2.my-4
      | 4. 您企业品牌站当前最需要的支持？
    .radio-group
      md-radio.md-primary(v-model="form.question4", :value="1")
        | 品牌站搭建
      md-radio.md-primary(v-model="form.question4", :value="2")
        | 品牌站升级
      md-radio.md-primary(v-model="form.question4", :value="3")
        | 增加流量及销售
      md-radio.md-primary(v-model="form.question4", :value="4")
        | 整体品牌营销
    md-button.md-dense.md-raised.md-primary.line-btn(@click="nextQuestion")
      | 下一题
  .section(v-if="form.question4")
    span.clickable(@click="rollbackQuestion")
      md-icon.span-icon undo
      .mini-spacer
      span 返回上一题
    p.progess-p
      | 当前进度
    progress-bar.progress(:value="80").my-4
    h2.my-4
      | 5. 您企业品牌站的所处阶段？
    .radio-group
      md-radio.md-primary(v-model="form.question5", :value="1")
        | 0 到 1，品牌站搭建
      md-radio.md-primary(v-model="form.question5", :value="2")
        | 1 到 10，已有品牌站，增加销售
      md-radio.md-primary(v-model="form.question5", :value="3")
        | 10 到 100，扩大规模，强化品牌
    md-button.md-dense.md-raised.md-primary.line-btn(@click="nextQuestion")
      | 完成
  .section(v-if="form.question5 === 1")
    h2.my-8
      | 评估结果：您的出海品牌站，
      br
      | 处于 0 到 1 起步阶段
    h2.my-4
      | 当前目标
    p.my-8
      | 建立无缝对接流量平台的品牌电商网站
    h2.my-4
      | 快速行动
    p.my-4
      | 即刻扫码进 “起步群”，享更多资源和持续支持。
    img.qrcode.my-4(src="~@/assets/images/shopify/startup.png").my-8
    p
      | 预约 Google 专家，进行 1 对 1 咨询
    md-button.md-dense.md-raised.md-primary.line-btn(href="https://services.google.cn/fb/forms/thinkretailshopify/")
      | 立即预约
  .section(v-if="form.question5 === 2")
    h2.my-8
      | 评估结果：您的出海品牌站，
      br
      | 处于 1 到 10 加速阶段
    h2.my-4
      | 当前目标
    p.my-8
      | 开通谷歌商户中心 (Google Merchant Center)，
      br
      | 通过免费流量和广告流量，实现销量成长
    h2.my-4
      | 快速行动
    p
      | 即刻扫码进 “加速群”，享更多资源和持续支持。
    img.qrcode.my-4(src="~@/assets/images/shopify/acc.png").my-8
    p
      | 预约 Google 专家，进行 1 对 1 咨询
    md-button.md-dense.md-raised.md-primary.line-btn(href="https://services.google.cn/fb/forms/thinkretailshopify/")
      | 立即预约
  .section(v-if="form.question5 === 3")
    h2.my-8
      | 评估结果：您的出海品牌站，
      br
      | 处于 10 到 100 规模扩张阶段
    h2.my-4
      | 当前目标
    p
      | 升级 Shopify 品牌店铺，优化购物流程，增强品牌体验
    p.my-8
      | 基于 Google 用户洞察，进行品类，地域及引流方式的拓展
    h2.my-4
      | 快速行动
    p
      | 即刻扫码，与 Shopify 专家 1:1 直接沟通
    img.qrcode.my-4(src="~@/assets/images/shopify/expert.png").my-8
    p
      | 预约 Google 专家，进行 1 对 1 咨询
    md-button.md-dense.md-raised.md-primary.line-btn(href="https://services.google.cn/fb/forms/thinkretailshopify/")
      | 立即预约
</template>

<script>
import ProgressBar from "@/components/ProgressBar";

export default {
  layout: "assessment",
  components: { ProgressBar },
  watch: {
    route() {
      this.$refs.fullpage.api.setAllowScrolling(false);
      this.$refs.fullpage.api.setKeyboardScrolling(false);
    },
  },
  data() {
    return {
      route: window.location.hash,
      form: {
        channel: "",
        category: "",
        question1: 0,
        question2: 0,
        question3: 0,
        question4: 0,
        question5: 0,
      },
      options: {
        licenseKey: "29F421B7-0B7A43EC-81C4733D-59CE7D92",
        menu: "#menu",
        scrollOverflow: true,
        allowScrolling: false,
        anchors: [
          "cover",
          "question1",
          "question2",
          "question3",
          "question4",
          "question5",
          "result",
        ],
      },
    };
  },
  methods: {
    nextQuestion() {
      if (this.form.question5 > 0) {
        // 已完成
        this.$pushEvent("form_sites", { form: this.form });
      }
      this.$refs.fullpage.build();
      this.$refs.fullpage.api.moveSectionDown();
      this.$refs.fullpage.api.setAllowScrolling(false);
      this.$refs.fullpage.api.setKeyboardScrolling(false);
    },
    rollbackQuestion() {
      this.$refs.fullpage.build();
      this.$refs.fullpage.api.moveSectionUp();
      this.$refs.fullpage.api.setAllowScrolling(false);
      this.$refs.fullpage.api.setKeyboardScrolling(false);
    },
  },
  mounted() {
    this.$pushEvent("visit", { route: "at_sites" });
    this.$refs.fullpage.api.setAllowScrolling(false);
    this.$refs.fullpage.api.setKeyboardScrolling(false);
  },
};
</script>

<style lang="stylus" scoped>
.section-cover {
  padding: 0 !important;
}

.section-content {
  padding: 20vh 20px 0 20px;
  @media screen and (min-width: 1024px) {
    padding: 30vh 0 0 100px;
  }
}

.google-shopify-logo {
  position: absolute;
  top: 20px;
  right: 20px;
  @media screen and (min-width: 1024px) {
     width: 300px;
  }
  width: 150px;
}

.inline-field {
  display: inline;
  margin: 0 10px;
}

.inline-input {
  width: 100px;
  display: inline;
  height: 21px;
}

// Shared

.mini-spacer {
  width: 10px;
  display: inline-block;
}

.section {
  height: 100vh;
  overflow-y: scroll;
  background-image: url('~@/assets/images/background_assessment_mobile.png');
  background-size: cover;
  padding: 20px 20px 20px 20px;

  @media screen and (min-width: 1024px) {
    padding: 15vh 20vw 0 100px;
    background-image: url('~@/assets/images/background_assessment.png');
  }
}

.progress {
  @media screen and (min-width: 1024px) {
    width: 50vw
  }
}

h1 {
  font-size: 36px;
}

h2 {
  line-height: 1.4;
}

.description-text {
  line-height: 1.8;
}

.md-radio {
  display: flex;
  margin: 15px 0;
}

.description-text {
  @media screen and (min-width: 1024px) {
    max-width: 50%;
  }
}

.score-img {
  width: 80px;
  height: 80px;
}

.score-container {
  margin: 10px 0;
  display: flex;
}

.radio-group {
  display: grid;
}

.line-btn {
  display: flex;
  width: 100px;
  margin: 20px 0 0 0;
}

.qrcode {
  display: block;
  max-width: 72px;
}

.my-4 {
  margin: 0 0 20px 0;
}

.my-8 {
  margin: 0 0 40px 0;
}
</style>
